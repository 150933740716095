import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { HttpClient } from "@/services/HttpClient.service";
import { AxiosError } from "axios";
import {
  DataResponseListTransferToAsset,
  RequestTransferToAsset,
  ResponseListTransferToAssetForm,
  ResponseListTransferToAssetParam,
} from "../types/transfer-to-asset.type";

export class TransferToAssetServices extends HttpClient {
  createTransferToAsset = (
    payload: RequestTransferToAsset
  ): Promise<string> => {
    return this.post<string, RequestTransferToAsset>(
      Api.TransferToAsset,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListTransferToAsset = (
    params: RequestQueryParamsModel
  ): Promise<Pagination<DataResponseListTransferToAsset>> => {
    return this.get<Pagination<DataResponseListTransferToAsset>>(
      Api.TransferToAsset,
      {
        params,
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListTransferToAssetParam = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListTransferToAssetParam[]> => {
    return this.get<ResponseListTransferToAssetParam[]>(
      Api.TransferToAsset + "/param",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  getListTransferToAssetForm = (
    params: RequestQueryParamsModel
  ): Promise<ResponseListTransferToAssetForm> => {
    return this.get<ResponseListTransferToAssetForm>(
      Api.TransferToAsset + "/form",
      { params }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  printTransferToAsset = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.TransferToAsset + "/report/print", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };

  downloadTransferToAsset = (
    params: RequestQueryParamsModel
  ): Promise<ArrayBuffer> => {
    return this.get<ArrayBuffer>(Api.TransferToAsset + "/report/download", {
      params,
      responseType: "arraybuffer",
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  };
}

export const transferToAssetServices = new TransferToAssetServices();
