


















































































































































































































































import { debounce } from "@/helpers/debounce";
import { Row } from "@/hooks/table";
import useBlob from "@/hooks/useBlob";
import useDate from "@/hooks/useDate";
import useFIfoValuationReport from "@/hooks/useFifoValuationReport";
import MNotificationVue from "@/mixins/MNotification.vue";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { OptionModel } from "@/models/constant/interface/common.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import { BranchWarehouseParams } from "@/models/interface/branch";
import {
  FifoReportDownloadParams,
  FifoReportParams,
} from "@/models/interface/fifo-report";
import {
  HeadProductFifoValuationReportDTO,
  ProductFifoValuationReportDTO,
} from "@/models/interface/FifoValuationReport.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  DataWarehouseBranch,
  ResponseWarehouse,
} from "@/models/interface/logistic.interface";
import {
  DataListProduct,
  DataListProductCategory,
  IProductCategory,
  ResponseProduct,
} from "@/models/interface/product.interface";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import { FormModel } from "ant-design-vue";
import { Moment } from "moment";
import { VNode } from "vue";
import { Component, Mixins, Ref } from "vue-property-decorator";

type FormField = {
  productCategory: string | null;
  productName: string | null;
  productCode: string | null;
  fromDate: Moment | null;
  toDate: Moment | null;
  warehouse: string | null;
  branch: string | null;
};

type TableRow = Row<ProductFifoValuationReportDTO, number> & {
  name: string; // merge customer or supplier name as one column
};

@Component
export default class FifoReport extends Mixins(MNotificationVue) {
  @Ref("ruleForm") ruleForm!: FormModel;
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  PAGE_SIZE_OPTIONS = PAGE_SIZE_OPTIONS;

  form: FormField = {
    productCategory: "",
    productName: "",
    productCode: "",
    fromDate: null,
    toDate: null,
    warehouse: "",
    branch: "",
  };
  formRules = {
    productCategory: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    productName: [
      {
        required: false,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    productCode: [
      {
        required: true,
        message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
      },
    ],
    fromDate: [],
    toDate: [],
    branch: [],
    warehouse: [],
  };

  columnCogsIn = [
    {
      title: this.$t("lbl_price_in"),
      dataIndex: "priceIn",
      scopedSlots: { customRender: "currency" },
      width: 100,
    },
    {
      title: this.$t("lbl_qty_in"),
      dataIndex: "qtyIn",
      scopedSlots: { customRender: "number" },
      width: 100,
    },
    {
      title: this.$t("lbl_total_in"),
      dataIndex: "totalIn",
      scopedSlots: { customRender: "currency" },
      width: 100,
    },
  ];

  columnCogsOut = [
    {
      title: this.$t("lbl_price_out"),
      dataIndex: "priceOut",
      scopedSlots: { customRender: "currency" },
      width: 100,
    },
    {
      title: this.$t("lbl_qty_out"),
      dataIndex: "qtyOut",
      scopedSlots: { customRender: "number" },
      width: 100,
    },
    {
      title: this.$t("lbl_total_out"),
      dataIndex: "totalOut",
      scopedSlots: { customRender: "currency" },
      width: 100,
    },
  ];

  columnBalance = [
    {
      title: this.$t("lbl_price_balance"),
      dataIndex: "priceBalance",
      scopedSlots: { customRender: "currency" },
    },
    {
      title: this.$t("lbl_qty_balance"),
      dataIndex: "qtyBalance",
      scopedSlots: { customRender: "number" },
    },
    {
      title: this.$t("lbl_total_balance"),
      dataIndex: "totalBalance",
      scopedSlots: { customRender: "currency" },
    },
  ];

  tableColumns = [
    {
      title: this.$t("lbl_document_number"),
      dataIndex: "documentNumber",
      key: "documentNumber",
      width: 190,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_document_relation"),
      dataIndex: "documentRelation",
      key: "documentRelation",
      width: 190,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_invoice_number"),
      dataIndex: "invoice",
      key: "invoice",
      width: 190,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_branch"),
      dataIndex: "branchName",
      key: "branchName",
      width: "120px",
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "productLocation",
      key: "productLocation",
      width: 250,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_date"),
      dataIndex: "transactionDate",
      key: "transactionDate",
      width: 150,
      scopedSlots: { customRender: "date" },
    },
    {
      title: this.$t("lbl_supplier_customer_name"),
      dataIndex: "name",
      key: "name",
      width: 350,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "nullable" },
    },
  ];
  dataSource: TableRow[] = [];
  dataReport: HeadProductFifoValuationReportDTO = {
    grandTotalQtyIn: 0,
    grandTotalQtyOut: 0,
    grandTotalIn: 0,
    grandTotalOut: 0,
    grandTotalQtyBalance: 0,
    grandTotalBalance: 0,
    data: [],
  };
  dataBranch: DataWarehouseBranch[] = [];
  categoryList = {
    data: [] as IProductCategory[],
    fetching: false,
    search: "",
  };
  productList = {
    fetching: false,
    data: [] as ResponseProduct[],
    search: "",
  };
  warehouseList = {
    data: [] as OptionModel[],
    search: "",
  };
  param: RequestQueryParamsModel = {
    page: 0,
    limit: 10,
    search: "",
    productId: "",
    sorts: "date:asc",
  };

  loading = {
    download: false,
    print: false,
    find: false,
  };

  downloadPrintParam = {
    productCategory: "ALL",
    productName: "ALL",
    productCode: "ALL",
    fromDate: "ALL",
    warehouse: "ALL",
    toDate: "ALL",
    branch: "ALL",
  };

  created(): void {
    this.getProductCategoryList();
    this.getProductList();
    this.getWarehouseList();
    this.getBranch("");
  }

  validateForm(type: "download" | "list"): void {
    this.ruleForm.validate(valid => {
      if (!valid) return;
      if (type === "list") this.handleFind(this.form);
      else if (type === "download") this.downloadReport(this.form);
    });
  }

  buildParamsList(values: FormField): FifoReportParams {
    const { toStartDay, toEndDay } = useDate();
    let fromDate: string | undefined;
    if (values.fromDate) {
      fromDate = toStartDay(values.fromDate).format();
    }

    let toDate: string | undefined;
    if (values.toDate) {
      toDate = toEndDay(values.toDate).format();
    }

    const params: FifoReportParams = {
      startDate: fromDate,
      endDate: toDate,
      branchId: values.branch || undefined,
      categoryId: values.productCategory || undefined,
      warehouseId: values.warehouse || undefined,
      productId: values.productCode || undefined,
    };
    return params;
  }

  handleFind(values: FormField): void {
    const params: FifoReportParams = this.buildParamsList(values);
    this.getFifoReport(params);
  }

  resetForm(): void {
    this.ruleForm.resetFields();
    for (const key in this.downloadPrintParam) {
      this.downloadPrintParam[key] = "ALL";
    }
  }

  getProductCategoryList(): void {
    this.categoryList.fetching = true;
    const params = {
      limit: 20,
      page: 0,
    } as RequestQueryParamsModel;
    if (this.categoryList.search)
      params.search = `name~${this.categoryList.search}`;
    productService
      .listProductCategory(params)
      .then((res: DataListProductCategory) => {
        this.categoryList.data = res.data;
      })
      .finally(() => (this.categoryList.fetching = false));
  }

  getBranch(valueSearch) {
    const params = new BranchWarehouseParams();
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loadingBranch = false));
  }

  getProductList(): void {
    const search: string[] = [];
    if (this.form.productCategory) {
      search.push(`category.secureId~${this.form.productCategory}`);
    }
    if (this.productList.search) {
      search.push(`code~*${this.productList.search}*`);
    }
    const params = {
      limit: 20,
      page: 0,
      search: search.join("_AND_"),
    } as RequestQueryParamsModel;

    productService.listProduct(params).then((res: DataListProduct) => {
      this.productList.data = res.data;
    });
  }

  onChangeProductCode(productId: string, option: VNode): void {
    this.downloadPrintParam.productName = option.key
      ?.toString()
      .split("~")[0] as string;
    productService.detailProduct(productId).then(res => {
      this.form.productName = res["name"];
    });
  }

  handleCategoryProductChange(categoryId: string, option: VNode): void {
    if (categoryId) {
      this.downloadPrintParam.productCategory = option.key
        ?.toString()
        .split("~")[0] as string;
      this.form.productCode = "";
      this.form.productName = "";
      this.getProductList();
    }
  }

  handleSearchCategory(search: string): void {
    debounce(() => {
      this.categoryList.search = search ? `*${search}*` : "";
      this.getProductCategoryList();
    });
  }

  handleSearchProduct(search: string): void {
    debounce(() => {
      this.productList.search = search;
      this.getProductList();
    });
  }

  getWarehouseList(): void {
    const params = {
      limit: 20,
      page: 0,
    } as RequestQueryParamsModel;

    if (this.warehouseList.search) {
      params.search = `name~*${this.warehouseList.search}*`;
    }

    logisticServices
      .listWarehouse(params, "")
      .then((res: ResponseWarehouse) => {
        this.warehouseList.data = res.data.map(d => {
          return {
            label: d.name,
            value: d.id,
          };
        });
      });
  }

  handleSearchWarehouse(search = ""): void {
    debounce(() => {
      this.warehouseList.search = search;
      this.getWarehouseList();
    });
  }

  disableStartDate(startDate: Moment): boolean {
    const endDate = this.form.toDate;
    if (!startDate || !endDate) {
      return false;
    }

    return startDate.valueOf() > endDate.valueOf();
  }

  disableEndDate(endDate: Moment): boolean {
    const startDate = this.form.fromDate;
    if (!startDate || !endDate) {
      return false;
    }

    return startDate.valueOf() >= endDate.valueOf();
  }

  async getFifoReport(params?: FifoReportParams): Promise<void> {
    const { findAll, toTableRow } = useFIfoValuationReport();
    try {
      this.loading.find = true;
      const res: HeadProductFifoValuationReportDTO = await findAll(params);
      this.dataReport = res;
      this.dataSource = toTableRow(this.dataReport.data).map(item => ({
        ...item,
        name: item.supplierName || item.customerName,
      }));
    } catch (error) {
      this.showNotifError("notif_process_fail");
    } finally {
      this.loading.find = false;
    }
  }

  buildParamReport(field: FormField): string {
    const { toDefaultFormat } = useDate();
    const { buildParam } = useFIfoValuationReport();
    const { productCategory, warehouse } = this.downloadPrintParam;

    const productOpt = this.productList.data.find(
      e => e.id === field.productCode
    );
    const branchOpt = this.dataBranch.find(e => e.id === field.branch);

    return buildParam({
      company: this.$store.state.authStore.authData.companyName,
      branch: branchOpt?.name || "ALL",
      fromDate: field.fromDate ? toDefaultFormat(field.fromDate) : "ALL",
      productCategory: productCategory,
      dateTo: field.toDate ? toDefaultFormat(field.toDate) : "ALL",
      productName: field.productName || "ALL",
      productCode: productOpt?.code || "ALL",
      warehouse: warehouse,
    });
  }

  downloadReport(field: FormField): void {
    this.loading.download = true;
    const { download } = useFIfoValuationReport();
    const { toDownload } = useBlob();
    const paramsList = this.buildParamsList(field);
    const params: FifoReportDownloadParams = {
      ...paramsList,
      params: this.buildParamReport(field),
      sort: "date",
      direction: "asc",
    };

    download(params)
      .then(data => {
        toDownload(data, "fifo_report.xlsx");
      })
      .finally(() => (this.loading.download = false));
  }

  handleWarehouseChange(value: string, option: VNode): void {
    if (value) {
      this.downloadPrintParam.warehouse = option.key
        ?.toString()
        .split("~")[0] as string;
    } else {
      this.downloadPrintParam.warehouse = "ALL";
    }
  }
}
